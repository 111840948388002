<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :setting-columns="columns"
        :endpoint-list="ENDPOINT.KYY_STUDENT_ORDER_BY_MONTHS"
        :isDisableButton="isDisableButton"
        @getTotal="getDataTotal"
      >
        <template v-slot:table-menu-center>
          <div class="row mt-2 pb-5 c_total_area">
            <div class="col-xl-4 col-lg-4 col-sm-4 pt-3"
                 style="margin-left: 7px; margin-right: -7px; padding-right: 0; padding-left: 0;"
            >
              <div class="c_background_gray c_border_radius_1 p-4">
                <label>{{ $t('student_order.revenue') }}</label>
                <h3 class="c_text_align_center c_font_size_40px">{{ totalPrice ? formatAmount(totalPrice) : '0' }}</h3>
              </div>
              <div class="c_background_gray c_border_radius_1 p-4 mt-4">
                <label>{{ $t('student_order.order_amount') }}</label>
                <h3 class="c_text_align_center c_font_size_40px">{{ totalRecord ? totalRecord : '0' }}</h3>
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-sm-1 pl-0"></div>
            <div class="col-xl-7 col-lg-7 col-sm-7 pt-3"
                 style="padding-left: 4px;"
            >
              <fieldset class="c_chart_area">
                <div class="row mt-2 pb-2 pl-3">
                  <div class="col-xl-12 col-lg-12 col-sm-12 pl-0 font-weight-bold">{{ $t('student_order.3months') }}</div>
                </div>
                <div id="chart" ref="chart" style="width: 100%;height:100%;"></div>
              </fieldset>
            </div>
          </div>
        </template>

        <template v-slot:body-cell-detail="props">
          <td class="app-align-middle text-center app-p-none c_w_10_percent">
            <button
              @click="handleBtnDetailClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("sale.detail") }}
            </button>
          </td>
        </template>

        <template v-slot:body-cell-month="props">
          <td class="text-center">
            <p class="p- m-0">{{ __formatDate(props.row.month, 'YYYY/MM') }}</p>
          </td>
        </template>

        <template v-slot:body-cell-amount="props">
          <td class="text-center">
            <p class="p- m-0">{{ formatAmount(props.row.amount) }}</p>
          </td>
        </template>
      </app-basic-table>
    </validation-observer>
  </div>
</template>

<script>
import SaleListTable from "@/pages/admin/kyy_student_order/SaleListTable";
import {ENDPOINT} from "../../../constants/api";
import {MonthPickerInput} from 'vue-month-picker'
import moment from 'moment'

import * as echarts from 'echarts';

var myChart;
export default {
  name: "SaleListPage",
  components: {
    "app-basic-table": SaleListTable,
    MonthPickerInput,
  },
  data() {
    return {
      monthList: [
        moment().subtract(2, 'months').format('M月'),
        moment().subtract(1, 'months').format('M月'),
        moment().format('M月')
      ],
      monthSelect: moment().format('YYYY/MM/01'),
      columns: [
        {name: "month", label: this.$t('student_order.month'), textAlign: 'text-center', class: 'c_w_10_percent'},
        {name: "number_item", label: this.$t('student_order.order_amount'), sortable: true, textAlign: 'text-center', class: 'c_w_40'},
        {name: "amount", label: this.$t('student_order.revenue'), sortable: true, textAlign: 'text-right', class: 'c_w_40'},
      ],
      endPoint: '',
      totalPrice: 0,
      totalRecord: 0,
      currentMonth: {
        price: 0,
        record: 0
      },
      prevMonth: {
        price: 0,
        record: 0
      },
      multiPrevMonth: {
        price: 0,
        record: 0
      },
      isDisableButton : false
    };
  },
  methods: {
    createChart() {
      let chartDom = this.$refs.chart
      myChart = echarts.init(chartDom);
      let option;
      const colors = ['#057ED3', '#77B008'];
      option = {
        color: colors,
        sizeAutoCapable: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '15%',
          left: '20%',
        },
        toolbox: {
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {show: false}
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            // prettier-ignore
            data: this.monthList
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.$t("student_order.revenue"),
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          },
          {
          },
          {
            type: 'value',
            name: this.$t("student_order.order_amount"),
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value}'
            }},
        ],
        series: [
          {
            name: this.$t("student_order.total_amount"),
            type: 'bar',
            barWidth: '40%',
            data: [
              {
                value: this.multiPrevMonth.price,
                itemStyle: {
                  color: '#D9D9D9'
                },
              },
              {
                value: this.prevMonth.price,
                itemStyle: {
                  color: '#D9D9D9'
                }
              },
              {
                value: this.currentMonth.price,
                itemStyle: {
                  color: colors[0]
                }
              }
            ]
          },
          {
            name: this.$t("student_order.total_order"),
            type: 'line',
            color: [colors[1]],
            yAxisIndex: 2,
            data: [
              this.multiPrevMonth.record, this.prevMonth.record, this.currentMonth.record
            ]
          }
        ]
      };

      option && myChart.setOption(option);
    },
    handlerApartmentNameClick(entry) {
      let routeApartment = this.$router.resolve({
        name: this.ROUTES.ADMIN.APARTMENT_EDIT,
        params: {id: entry.apartment_id}
      });
      window.open(routeApartment.href, '_blank');
    },
    handleBtnDetailClick(entry) {
      this.$router.push({
        name: this.ROUTES.ADMIN.KYY_STUDENT_ORDER_LIST,
        query: {
          "filters.apartment_id.equal": entry.apartment_id,
          "filters.month.equal": this.monthSelect ?? moment().format('YYYY/MM/01'),
          "prev":"salelist"
        },
      });
    },
    async getDataTotal(params) {
      moment.locale('vi')
      this.monthList = [
        moment(params.month).subtract(2, 'months').format('MMMM'),
        moment(params.month).subtract(1, 'months').format('MMMM'),
        moment(params.month).format('MMMM')
      ]
      this.monthSelect = moment(params.month).format('YYYY/MM/01')
      this.resetTotalData();
      this.isDisableButton = true
      await this.$request
        .get(ENDPOINT.KYY_STUDENT_ORDER_BY_MONTHS, {month: params.month})
        .then((res) => {
          res.data.data.map((item) => {
            //current month
            if (item.month === moment(params.month).format('YYYYMM')) {
              this.totalPrice = item.amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              this.totalRecord = item.number_item.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              this.currentMonth = {
                price: item.amount,
                record: item.number_item,
              }
            } else if (item.month === moment(params.month).subtract(1, 'months').format('YYYYMM')) {
              this.prevMonth = {
                price: item.amount,
                record: item.number_item,
              }
            } else if (item.month === moment(params.month).subtract(2, 'months').format('YYYYMM')) {
              this.multiPrevMonth = {
                price: item.amount,
                record: item.number_item,
              }
            }
          })
        this.createChart()
        this.isDisableButton = false
      })
    },
    resetTotalData() {
      this.totalPrice = 0
      this.totalRecord = 0
      this.currentMonth = {
        record: 0,
        price: 0
      }
      this.prevMonth = {
        record: 0,
        price: 0
      }
      this.multiPrevMonth = {
        record: 0,
        price: 0
      }
    },
    formatAmount(amount) {
      let number = parseFloat(amount.replace(/,/g, ''));

      return number.toLocaleString('en-US', { maximumFractionDigits: 0 }) + ' VND';
    }
  },
  mounted: async function () {
  },
}
window.addEventListener('resize',function(){
  myChart.resize();
})
</script>
